import HeroImageSVG from "../assets/HeroImage2.svg";
import OrcafinaLogoSVG from "../assets/OrcafinaLogo2.svg";
import LoopsLogoSVG from "../assets/Loops2Logo.svg";
import YCLogoSVG from "../assets/YCLogo.svg";
import DVLogoSVG from "../assets/DVLogo.svg";

import "../App.css";

function LandingScreenMobile() {
  return (
    <div
      style={{
        backgroundColor: "#FEF7F1",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px 5%",
          height: "10vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            src={OrcafinaLogoSVG}
            style={{ height: "1em", width: "auto", marginRight: "0.5em" }}
            alt="OrcafinaLogoSVG"
          />
          <p
            style={{ marginBlock: "0px", fontWeight: "700", fontSize: "16px" }}
          >
            Orcafina
          </p>
        </div>
        {/* <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="TopLinkText">
            <p
              style={{
                marginBlock: "0px",
                fontWeight: "400",
                fontSize: "16px",
              }}
            >
              Features
            </p>
          </div>
          <div className="TopLinkText">
            <p
              style={{
                marginBlock: "0px",
                fontWeight: "400",
                fontSize: "16px",
              }}
            >
              Contact
            </p>
          </div>
        </div> */}
      </div>
      <div
        style={{
          minHeight: "90vh",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          padding: "0 10%",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            padding: "0 3vw 0 5vw",
            // backgroundColor: "blue",
          }}
        >
          <img
            src={HeroImageSVG}
            style={{ height: "auto", width: "80vw" }}
            alt="HeroImageSVG"
          />
        </div>
        <div
          style={{
            flex: 1,
            // backgroundColor: "yellow",
          }}
        >
          <p
            style={{ marginBlock: "0px", fontWeight: "700", fontSize: "40px" }}
          >
            Verify bank <br /> accounts. <br />{" "}
            <span style={{ color: "#8B8600" }}>Lightning</span> fast.
          </p>
          <p
            style={{
              margin: "3vh 0 0 0",
              fontWeight: "400",
              fontSize: "18px",
              maxWidth: "80vw",
            }}
          >
            Validate bank accounts without 3-day micro-deposits. Near-instant on
            the RTP network.
          </p>
          <div
            style={{
              display: "flex",
              marginTop: "3vh",
              height: "2.5em",
              alignItems: "center",
            }}
          >
            <a
              className="ContactButton"
              href="https://airtable.com/shriGl6QhOIBFTIrv"
              target="_blank"
            >
              <p
                style={{
                  //   color: "white",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                Get a demo
              </p>
            </a>
            <p
              style={{
                margin: "0 20px",
                color: "black",
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              Go live in minutes, <br /> not months.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "5vh",
              height: "2.5em",
              //   alignItems: "center",
            }}
          >
            <img
              src={YCLogoSVG}
              style={{ height: "1.5em", width: "auto", marginRight: "1.5em" }}
              alt="YCLogoSVG"
            />
            <img
              src={DVLogoSVG}
              style={{ height: "1.5em", width: "auto", marginRight: "1.5em" }}
              alt="DVLogoSVG"
            />
            <img
              src={LoopsLogoSVG}
              style={{ height: "1.5em", width: "auto", marginRight: "0em" }}
              alt="LoopsLogoSVG"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingScreenMobile;
